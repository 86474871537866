import { isEmpty } from 'lodash';
import { FC, useMemo } from 'react';
import { ChainId, getChainIconSrc, Ido } from 'sdk';

type PropsType = {
  project: Ido
}

const getProjectChainIds = (ido: Ido) => {
  return ido.claimCurrency?.filter(({ chainId }) => chainId).map(({ chainId }) => chainId);
};

const getChainIcon = (chainId: ChainId) => {
  return (
    <img
      key={`chain-icon-${chainId}`}
      src={getChainIconSrc(chainId)}
      style={{ width: 22, height: 22, marginLeft: 5 }}
    />
  );
};

const IdoClaimNetworksList: FC<PropsType> = ({ project }) => {

  const idoClaimChainIds = useMemo(() => {
    return getProjectChainIds(project)
  }, [project])

  if (isEmpty(idoClaimChainIds)) {
    return <>TBA</>
  }
  return (
    <>
      {getProjectChainIds(project)?.map((chainId) => getChainIcon(chainId))}
    </>
  )
}

export default IdoClaimNetworksList
