export enum ChainId {
  UNSET = 0,
  BINANCE_MAINNET = 56,
  BINANCE_TESTNET = 97,
  CSC_MAINNET = 52,
  CSC_TESTNET = 53,
  OKC_MAINNET = 66,
  OKC_TESTNET = 65,
  POLYGON_MAINNET = 137,
  POLYGON_TESTNET = 80001,
  ETHEREUM_MAINNET = 1,
  ETHEREUM_GOERLI = 5,
  BRISE_TESTNET = 64668,
  BRISE_MAINNET = 32520,
  CADUCEUS_TESTNET = 512512,
  CADUCEUS_MAINNET = 256256,
  TELOS_TESTNET = 41,
  TELOS_MAINNET = 40,
  OPTIMISM_TESTNET = 420,
  OPTIMISM_MAINNET = 10,
  ARBITRUM_ONE = 42161,
  ARBITRUM_NOVA = 42170,
  ARBITRUM_TESTNET = 421613,
  ZETACHAIN_TESTNET = 7001,
  NEOX_TESTNET = 12227329,
  ZCSYNC_MAINNET = 324,
  ZCSYNC_TESTNET = 280,
  FIRE_MAINNET = 0, // FIXME update chainId
}

export enum Wallet {
  Metamask = "Metamask",
  TrustWallet = "Trust Wallet",
  WalletConnect = "WalletConnect",
  OKX = "OKX Wallet",
}

export enum NetworkName {
  UNSET = "TBA",
  BINANCE_MAINNET = "BNB Chain",
  BINANCE_TESTNET = "BNB Chain Testnet",
  CSC_MAINNET = "CSC",
  CSC_TESTNET = "CSC Testnet",
  OKC_MAINNET = "OKC",
  OKC_TESTNET = "OKC Testnet",
  POLYGON_MAINNET = "Polygon",
  POLYGON_TESTNET = "Polygon Testnet",
  ETHEREUM_MAINNET = "Ethereum",
  ETHEREUM_GOERLI = "Goerli",
  BRISE_TESTNET = "Brise Chain Testnet",
  BRISE_MAINNET = "Brise Chain",
  FIRE_MAINNET = "5ire",
  CADUCEUS_TESTNET = "CMP-Testnet",
  CADUCEUS_MAINNET = "Caduceus",
  TELOS_TESTNET = "Telos EVM Testnet",
  TELOS_MAINNET = "Telos",
  OPTIMISM_TESTNET = "Optimism testnet",
  OPTIMISM_MAINNET = "Optimism",
  ARBITRUM_ONE = "Arbitrum One",
  ARBITRUM_NOVA = "Arbitrum Nova",
  ARBITRUM_TESTNET = "Arbitrum Testnet",
  ZETACHAIN_TESTNET = "ZetaChain",
  NEOX_TESTNET = "NEOX Chain",
  ZCSYNC_MAINNET = "zkSync Era",
  ZCSYNC_TESTNET = "zkSync Era Testnet",
}

export enum LinkName {
  Telegram = "telegram",
  Twitter = "twitter",
  Website = "website",
  Discord = "discord",
  Instagram = "instagram",
  Medium = "medium",
  Document = "document",
  Bscscan = "bscscan",
  Pitchdeck = "pitchdeck",
  Whitepaper = "whitepaper",
  Tokenomics = "tokenomics",
  SweepWidget = "sweep widget",
}

export enum SocialLinkName {
  Telegram = "telegram",
  Twitter = "twitter",
  Website = "website",
  Discord = "discord",
  Instagram = "instagram",
  Medium = "medium",
  Document = "document",
  Bscscan = "bscscan",
}

export enum DocsLinkName {
  Pitchdeck = "pitchdeck",
  Whitepaper = "whitepaper",
  Tokenomics = "tokenomics",
}

export enum Language {
  EN = "en",
  RU = "ru",
}

export enum TierName {
  TIER_1 = "Earth",
  TIER_2 = "Orbit",
  TIER_3 = "Galaxy",
  TIER_4 = "Universe",
}

export enum TierRequiredAmount {
  TIER_1 = "0",
  TIER_2 = "2000",
  TIER_3 = "5000",
  TIER_4 = "20000",
}
