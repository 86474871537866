import { useMemo } from "react";

export const useIdoTitlePostfix = (projectId?: string) => {
  return useMemo(() => {
    if (!projectId) return;
    const style = { textTransform: "none", opacity: 0.3, fontSize: 18 } as const;
    let postfix = "";
    switch (projectId) {
      case "6464c4bc2d3b5cea84d59498": {
        postfix = "Community Sale";
        break;
      }
      case "64b6904716faee0966a94ab0": {
        postfix = "Private Sale";
        break;
      }
      case "64f6efa57ae61eefb3544389": {
        postfix = "Giveaway";
        break;
      }
      case "657178c119d6833dbcc21743": {
        postfix = "Private Sale - 2";
        break;
      }
      case "65819af194c10dab7cdb44fe": {
        postfix = "KOLs";
        break;
      }
      case "658a9b6394cbe3e897e4d947": {
        postfix = "Giveaway";
        break;
      }
    }
    if (postfix) {
      return { postfix, style };
    }
  }, [projectId]);
};
