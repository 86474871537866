//////////////////////
import { customChains } from "app/constants";
import {
  arbitrum,
  bsc,
  bscTestnet,
  goerli,
  mainnet,
  okc,
  optimism,
  polygon,
  polygonMumbai,
  telos,
  zkSync,
} from "wagmi/chains";

const advancedChains =
  process.env.REACT_APP_ENVIRONMENT === "development" ? [bscTestnet, goerli, polygonMumbai] : [];
export const chains = [
  bsc,
  arbitrum,
  mainnet,
  polygon,
  okc,
  telos,
  optimism,
  zkSync,
  ...advancedChains,
  ...customChains,
];
export const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
