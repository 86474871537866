import { EXPLORERS_MAP, NETWORK_NAMES_MAP, NODES_MAP } from "./constants";
import { ChainId } from "./enums";
import { ChainData } from "./types";

export const supportedChainsProduction: ChainData[] = [
  {
    name: "Ethereum Mainnet",
    short_name: "eth",
    chain: "ETH",
    network: "mainnet",
    chain_id: 1,
    network_id: 1,
    rpc_url: "https://rpc.ankr.com/eth",
    native_currency: {
      symbol: "ETH",
      name: "Ethereum",
      decimals: 18,
    },
  },
  {
    name: "Binance Smart Chain",
    short_name: "bsc",
    chain: "smartchain",
    network: "mainnet",
    chain_id: 56,
    network_id: 56,
    rpc_url: "https://bsc-dataseed.binance.org/",
    native_currency: {
      symbol: "BNB",
      name: "BNB",
      decimals: 18,
    },
    block_explorer_urls: ["https://bscscan.com/"],
  },
  {
    name: "OKXChain Mainnet",
    short_name: "okt",
    chain: "okxchain",
    network: "mainnet",
    chain_id: 66,
    network_id: 66,
    rpc_url: "https://exchainrpc.okex.org",
    native_currency: {
      name: "OKXChain Global Utility Token",
      symbol: "OKT",
      decimals: 18,
    },
    block_explorer_urls: ["https://www.oklink.com/okc/"],
  },
  {
    name: "CoinEx Smart Chain Mainnet",
    short_name: "cet",
    chain: "CSC",
    network: "mainnet",
    chain_id: 52,
    network_id: 52,
    rpc_url: "https://rpc.coinex.net",
    native_currency: {
      name: "CoinEx Chain Native Token",
      symbol: "cet",
      decimals: 18,
    },
    block_explorer_urls: ["https://www.coinex.net/"],
  },
  {
    name: "Polygon Mainnet",
    short_name: "MATIC",
    chain: "Polygon",
    network: "mainnet",
    chain_id: 137,
    network_id: 137,
    rpc_url: "https://polygon-rpc.com/",
    native_currency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    block_explorer_urls: ["https://polygonscan.com/"],
  },
  {
    name: "Brise Chain",
    short_name: "BRISE",
    chain: "Brise",
    network: "mainnet",
    chain_id: 32520,
    network_id: 32520,
    rpc_url: "https://nodes.vefinetwork.org/bitgert",
    native_currency: {
      name: "BRISE",
      symbol: "BRISE",
      decimals: 18,
    },
    block_explorer_urls: ["https://brisescan.com/"],
  },
  {
    name: NETWORK_NAMES_MAP[ChainId.CADUCEUS_MAINNET],
    short_name: "CADUCEUS",
    chain: "CADUCEUS",
    network: "mainnet",
    chain_id: ChainId.CADUCEUS_MAINNET,
    network_id: ChainId.CADUCEUS_MAINNET,
    rpc_url: NODES_MAP[ChainId.CADUCEUS_MAINNET],
    native_currency: {
      name: "CMP",
      symbol: "CMP",
      decimals: 18,
    },
    block_explorer_urls: [EXPLORERS_MAP[ChainId.CADUCEUS_MAINNET].url],
  },
  {
    name: NETWORK_NAMES_MAP[ChainId.TELOS_MAINNET],
    short_name: "TELOS",
    chain: "TELOS",
    network: "mainnet",
    chain_id: ChainId.TELOS_MAINNET,
    network_id: ChainId.TELOS_MAINNET,
    rpc_url: NODES_MAP[ChainId.TELOS_MAINNET],
    native_currency: {
      name: "TLOS",
      symbol: "TLOS",
      decimals: 18,
    },
    block_explorer_urls: [EXPLORERS_MAP[ChainId.TELOS_MAINNET].url],
  },
  {
    name: "Optimism",
    short_name: "Optimism",
    chain: "Optimism",
    network: "mainnet",
    chain_id: 10,
    network_id: 10,
    rpc_url: "https://mainnet.optimism.io",
    native_currency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    block_explorer_urls: ["https://optimistic.etherscan.io"],
  },
  {
    name: "Arbitrum One",
    short_name: "Arbitrum",
    chain: "Arbitrum",
    network: "mainnet",
    chain_id: 42161,
    network_id: 42161,
    rpc_url: "https://arb1.arbitrum.io/rpc",
    native_currency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    block_explorer_urls: ["https://arbiscan.io/", "https://explorer.arbitrum.io/"],
  },
  {
    name: "Arbitrum Nova",
    short_name: "Arbitrum",
    chain: "Arbitrum",
    network: "mainnet",
    chain_id: 42170,
    network_id: 42170,
    rpc_url: "https://nova.arbitrum.io/rpc",
    native_currency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    block_explorer_urls: ["https://nova.arbiscan.io/", "https://nova-explorer.arbitrum.io/"],
  },
  {
    name: "zkSync Era",
    short_name: "zkSync Era",
    chain: "zkSync Era",
    network: "mainnet",
    chain_id: 324,
    network_id: 324,
    rpc_url: "https://mainnet.era.zksync.io",
    native_currency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    block_explorer_urls: ["https://explorer.zksync.io"],
  },
];

const supportedChains: ChainData[] = [
  {
    name: "Hardhat",
    short_name: "hdh",
    chain: "ETH",
    network: "hardhat",
    chain_id: 31337,
    network_id: 31337,
    rpc_url: "http://localhost:8545/",
    native_currency: {
      symbol: "ETH",
      name: "ETH",
      decimals: 18,
    },
  },
  {
    name: "BSC Testnet",
    short_name: "bsc",
    chain: "smartchain",
    network: "testnet",
    chain_id: 97,
    network_id: 97,
    rpc_url: "https://data-seed-prebsc-1-s3.binance.org:8545",
    native_currency: {
      symbol: "BNB",
      name: "BNB",
      decimals: 18,
    },
    block_explorer_urls: ["https://testnet.bscscan.com/"],
  },
  {
    name: "OKXChain Testnet",
    short_name: "tokt",
    chain: "okxchain",
    network: "testnet",
    chain_id: 65,
    network_id: 65,
    rpc_url: "https://exchaintestrpc.okex.org",
    native_currency: {
      name: "OKExChain Global Utility Token in testnet",
      symbol: "OKT",
      decimals: 18,
    },
    block_explorer_urls: ["https://www.oklink.com/okc-test/"],
  },
  {
    name: "CoinEx Smart Chain Testnet",
    short_name: "tcet",
    chain: "CSC",
    network: "testnet",
    chain_id: 53,
    network_id: 53,
    rpc_url: "https://testnet-rpc.coinex.net/",
    native_currency: {
      name: "CoinEx Chain Test Native Token",
      symbol: "cet",
      decimals: 18,
    },
    block_explorer_urls: ["https://testnet.coinex.net/"],
  },
  {
    name: "Polygon Testnet",
    short_name: "maticmum",
    chain: "Polygon",
    network: "testnet",
    chain_id: 80001,
    network_id: 80001,
    rpc_url: "https://rpc-mumbai.maticvigil.com",
    native_currency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    block_explorer_urls: ["https://mumbai.polygonscan.com/"],
  },
  {
    name: "Brise Chain Testnet",
    short_name: "BRISE",
    chain: "Brise",
    network: "testnet",
    chain_id: 64668,
    network_id: 64668,
    rpc_url: "https://testnet-rpc.brisescan.com",
    native_currency: {
      name: "BRISE",
      symbol: "BRISE",
      decimals: 18,
    },
    block_explorer_urls: ["https://testnet-explorer.brisescan.com/"],
  },
  {
    name: NETWORK_NAMES_MAP[ChainId.CADUCEUS_TESTNET],
    short_name: "CADUCEUS",
    chain: "CADUCEUS",
    network: "mainnet",
    chain_id: ChainId.CADUCEUS_TESTNET,
    network_id: ChainId.CADUCEUS_TESTNET,
    rpc_url: NODES_MAP[ChainId.CADUCEUS_TESTNET],
    native_currency: {
      name: "CMP",
      symbol: "CMP",
      decimals: 18,
    },
    block_explorer_urls: [EXPLORERS_MAP[ChainId.CADUCEUS_TESTNET].url],
  },
  {
    name: NETWORK_NAMES_MAP[ChainId.TELOS_TESTNET],
    short_name: "TELOS",
    chain: "TELOS",
    network: "mainnet",
    chain_id: ChainId.TELOS_TESTNET,
    network_id: ChainId.TELOS_TESTNET,
    rpc_url: NODES_MAP[ChainId.TELOS_TESTNET],
    native_currency: {
      name: "TLOS",
      symbol: "TLOS",
      decimals: 18,
    },
    block_explorer_urls: [EXPLORERS_MAP[ChainId.TELOS_TESTNET].url],
  },
  {
    name: "Optimism Goerli Testnet",
    short_name: "Optimism",
    chain: "Optimism",
    network: "testnet",
    chain_id: 420,
    network_id: 420,
    rpc_url: "https://goerli.optimism.io",
    native_currency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    block_explorer_urls: ["https://optimism.io"],
  },
  {
    name: "Arbitrum Goerli Testnet",
    short_name: "Arbitrum",
    chain: "Arbitrum",
    network: "testnet",
    chain_id: 421613,
    network_id: 421613,
    rpc_url: "https://goerli.optimism.io",
    native_currency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    block_explorer_urls: [
      "https://goerli.arbiscan.io",
      "https://goerli-rollup-explorer.arbitrum.io",
    ],
  },
  {
    name: "zkSync Era Testnet",
    short_name: "zkSync Era Testnet",
    chain: "zkSync Era Testnet",
    network: "testnet",
    chain_id: 280,
    network_id: 280,
    rpc_url: "https://testnet.era.zksync.dev",
    native_currency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    block_explorer_urls: ["https://goerli.explorer.zksync.io"],
  },
];

const getSupportedChains = () =>
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? supportedChainsProduction
    : [...supportedChainsProduction, ...supportedChains];

export default getSupportedChains;
