import { expandedProjects, postponedProjects, refundedProjectsId } from "app/constants";
import { ProjectStage } from "app/enums";
import { FC } from "react";
import { Ido, RefundType } from "sdk";
import ProjectTag from "../project-tag";

export enum ProjectStatusTag {
  UPCOMING = "upcoming",
  LIVE = "live",
  CLOSED = "closed",
  REFUND = "refund",
  REFUNDED = "refunded",
  POSTPONED = "postponed",
  EXPANDED = "expanded",
}

type Props = {
  className?: string;
  ido: Ido;
  projectStage: ProjectStage;
};

const IdoTagsList: FC<Props> = ({ ido, className, projectStage }) => {
  return (
    <div className={className}>
      <div>
        {projectStage <= ProjectStage.whitelistGap && !postponedProjects.includes(ido.id) && (
          <ProjectTag status={ProjectStatusTag.UPCOMING} />
        )}

        {postponedProjects.includes(ido.id) && (
          <>
            <ProjectTag status={ProjectStatusTag.POSTPONED} />
          </>
        )}

        {expandedProjects.includes(ido.id) && (
          <>
            <ProjectTag status={ProjectStatusTag.EXPANDED} />
          </>
        )}

        {projectStage >= ProjectStage.whitelist &&
          projectStage <= ProjectStage.fcfs &&
          !postponedProjects.includes(ido.id) &&
          !expandedProjects.includes(ido.id) && (
            <>
              <ProjectTag status={ProjectStatusTag.LIVE} />
            </>
          )}

        {projectStage > ProjectStage.fcfs && projectStage < ProjectStage.refund && (
          <ProjectTag status={ProjectStatusTag.CLOSED} />
        )}

        {!refundedProjectsId.includes(ido.id) &&
          (ido.refundType === RefundType.WITHOUT_PROJECT_TOKENS) && (
            <ProjectTag status={ProjectStatusTag.REFUND} />
          )}

        {(refundedProjectsId.includes(ido.id) || ido.refundType === RefundType.REFUNDED) && (
          <ProjectTag status={ProjectStatusTag.CLOSED} />
        )}
      </div>

      <div>
        {ido.claimType.toLowerCase() === "gagarin" && <ProjectTag status={ido.network} />}
        {ido.claimType.toLowerCase() === "swords of blood" && <ProjectTag status="solana" />}
      </div>
    </div>
  );
};

export default IdoTagsList;
