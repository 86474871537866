import { ProjectStage } from "app/enums";
import cn from "classnames";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./project-fill.sass";

type Props = {
  className?: string;
  projectStage: ProjectStage;
  investedPercent: number;
  title: { text: string; separator: string | JSX.Element; date: string };
};

const ProjectFill: FC<Props> = ({
  className,
  projectStage,
  investedPercent,
  children,
  title: { text, separator, date },
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn("project-fill", className)}>
      {projectStage > ProjectStage.fcfs ? (
        <div className="project-fill__row">
          <div className="project-fill__filled">
            <Trans i18nKey="projectFill.filled">
              All tokens are sold out. <br /> Thank you for your participation.
            </Trans>
          </div>
        </div>
      ) : (
        <>
          <div className="project-fill__row">
            <div className="project-fill__title">
              {t(text)} {separator} {date}
            </div>
            <div className="project-fill__percent">{investedPercent}%</div>
          </div>
          <div className="project-fill__row">
            <div className="project-fill__bar">
              <div className="project-fill__bar-active" style={{ width: `${investedPercent}%` }} />
            </div>
          </div>
          <div className="project-fill__row">{children}</div>
        </>
      )}
    </div>
  );
};

export default ProjectFill;
