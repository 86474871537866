import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { RainbowKitProvider, getDefaultWallets } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import App from "app/app";
import "app/localisation/config";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { chains as defaultChains, projectId } from "services/wallet.service";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import "./index.css";
import "./staking-widget/style.css";
import { store } from "./store";
import "./styles.sass";

const { chains, publicClient, webSocketPublicClient } = configureChains(defaultChains, [
  publicProvider(),
]);

const { connectors } = getDefaultWallets({
  appName: "Gagarin App",
  projectId,
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API!,
  cache: new InMemoryCache(),
});

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <TonConnectUIProvider manifestUrl={`${process.env.REACT_APP_URL}/tonconnect-manifest.json`}>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <App />
          </Provider>
        </ApolloProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </TonConnectUIProvider>
);
