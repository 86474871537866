import i5ire from "./assets/icons/5ire-soon.png";
import iArbitrum from "./assets/icons/arbitrum-logo.svg";
import iArbitrumNova from "./assets/icons/arbitrum-nova.svg";
import iBnbChain from "./assets/icons/bnb-chain.svg";
import iBriseChain from "./assets/icons/brise-chain.svg";
import iCaduceus from "./assets/icons/caduceus.svg";
import iCsc from "./assets/icons/csc-chain.svg";
import iEthereum from "./assets/icons/ethereum-chain.svg";
import iNeox from "./assets/icons/neox.png";
import iOkChain from "./assets/icons/okc-chain.svg";
import iOptimism from "./assets/icons/optimism-logo.svg";
import iPolygon from "./assets/icons/polygon-chain.svg";
import iTelos from "./assets/icons/telos.png";
import iZcSync from "./assets/icons/zcSync.png";
import iZeta from "./assets/icons/zeta-chain.png";
import { ChainId, NetworkName, Wallet } from "./enums";
import { ChainConfig } from "./types";

export const getChainIconSrc = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.BINANCE_MAINNET:
    case ChainId.BINANCE_TESTNET:
      return iBnbChain;
    case ChainId.BRISE_MAINNET:
    case ChainId.BRISE_TESTNET:
      return iBriseChain;
    case ChainId.CSC_MAINNET:
    case ChainId.CSC_TESTNET:
      return iCsc;
    case ChainId.ETHEREUM_MAINNET:
    case ChainId.ETHEREUM_GOERLI:
      return iEthereum;
    case ChainId.OKC_MAINNET:
    case ChainId.OKC_TESTNET:
      return iOkChain;
    case ChainId.POLYGON_MAINNET:
    case ChainId.POLYGON_TESTNET:
      return iPolygon;
    case ChainId.CADUCEUS_MAINNET:
    case ChainId.CADUCEUS_TESTNET:
      return iCaduceus;
    case ChainId.TELOS_MAINNET:
    case ChainId.TELOS_TESTNET:
      return iTelos;
    case ChainId.OPTIMISM_MAINNET:
    case ChainId.OPTIMISM_TESTNET:
      return iOptimism;
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARBITRUM_TESTNET:
      return iArbitrum;
    case ChainId.ARBITRUM_NOVA:
      return iArbitrumNova;
    case ChainId.ZETACHAIN_TESTNET:
      return iZeta;
    case ChainId.NEOX_TESTNET:
      return iNeox;
    case ChainId.ZCSYNC_MAINNET:
    case ChainId.ZCSYNC_TESTNET:
      return iZcSync;
    default:
      return undefined;
  }
};

export const chainsConfigProduction: ChainConfig[] = [
  {
    title: NetworkName.BINANCE_MAINNET,
    icon: iBnbChain,
    id: ChainId.BINANCE_MAINNET,
    unsupportedWallets: [],
  },
  {
    title: NetworkName.ETHEREUM_MAINNET,
    icon: iEthereum,
    id: ChainId.ETHEREUM_MAINNET,
    unsupportedWallets: [],
  },
  {
    title: NetworkName.CSC_MAINNET,
    icon: iCsc,
    id: ChainId.CSC_MAINNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.OKC_MAINNET,
    icon: iOkChain,
    id: ChainId.OKC_MAINNET,
    unsupportedWallets: [Wallet.TrustWallet],
  },
  // {
  //   title: 'Avalanche',
  //   icon: iAvalanche,
  //   chainId: 43114
  // unsupportedWallets: []
  // },
  {
    title: NetworkName.POLYGON_MAINNET,
    icon: iPolygon,
    id: ChainId.POLYGON_MAINNET,
    unsupportedWallets: [],
  },
  {
    title: NetworkName.BRISE_MAINNET,
    icon: iBriseChain,
    id: ChainId.BRISE_MAINNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.CADUCEUS_MAINNET,
    icon: iCaduceus,
    id: ChainId.CADUCEUS_MAINNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.TELOS_MAINNET,
    icon: iTelos,
    id: ChainId.TELOS_MAINNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.OPTIMISM_MAINNET,
    icon: iOptimism,
    id: ChainId.OPTIMISM_MAINNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.ARBITRUM_ONE,
    icon: iArbitrum,
    id: ChainId.ARBITRUM_ONE,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.ARBITRUM_NOVA,
    icon: iArbitrumNova,
    id: ChainId.ARBITRUM_NOVA,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.FIRE_MAINNET,
    icon: i5ire,
    id: ChainId.FIRE_MAINNET,
    unsupportedWallets: [],
    disabled: true,
  },
];

export const chainsConfig: ChainConfig[] = [
  {
    title: NetworkName.BINANCE_TESTNET,
    icon: iBnbChain,
    id: ChainId.BINANCE_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.CSC_TESTNET,
    icon: iCsc,
    id: ChainId.CSC_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.OKC_TESTNET,
    icon: iOkChain,
    id: ChainId.OKC_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  // {
  //   title: 'Avalanche',
  //   icon: iAvalanche,
  //   chainId: 43114
  // unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet]
  // },
  {
    title: NetworkName.POLYGON_TESTNET,
    icon: iPolygon,
    id: ChainId.POLYGON_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.ETHEREUM_GOERLI,
    icon: iEthereum,
    id: ChainId.ETHEREUM_GOERLI,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.BRISE_TESTNET,
    icon: iBriseChain,
    id: ChainId.BRISE_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.CADUCEUS_TESTNET,
    icon: iCaduceus,
    id: ChainId.CADUCEUS_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.TELOS_TESTNET,
    icon: iTelos,
    id: ChainId.TELOS_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.OPTIMISM_TESTNET,
    icon: iOptimism,
    id: ChainId.OPTIMISM_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.ARBITRUM_TESTNET,
    icon: iArbitrum,
    id: ChainId.ARBITRUM_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.ZETACHAIN_TESTNET,
    icon: iZeta,
    id: ChainId.ZETACHAIN_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
  {
    title: NetworkName.NEOX_TESTNET,
    icon: iNeox,
    id: ChainId.NEOX_TESTNET,
    unsupportedWallets: [Wallet.OKX, Wallet.TrustWallet],
  },
];

const defaultProps = {
  isProduction: process.env.REACT_APP_ENVIRONMENT === "production",
};
/**
 *
 * @param isProduction set to false if testnet config needed
 * @returns array of configs for supported chains
 */
const getChainsConfig = (
  props: {
    isProduction: boolean;
  } = defaultProps
): ChainConfig[] => {
  return props.isProduction ? chainsConfigProduction : [...chainsConfig, ...chainsConfigProduction];
};

export const findChainConfig = (chainId: number): ChainConfig | undefined => {
  const chainsConfig = getChainsConfig();

  return chainsConfig.find(({ id }) => id === chainId);
};

export default getChainsConfig;
